body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.homeStyles_image__390Ug {
  /* Image styles */
}

.homeStyles_title__1qegz {
  /* Title styles */
}

.homeStyles_subtitle__M065n {
  /* Subtitle styles */
}

.homeStyles_spacing__3YZJb {
  /* Spacing styles */
}
.homeStyles_cardGridItem__2Cp2q {
  display: flex;
}

.homeStyles_cardWrapper__1f0Mb {
  width: 100%;
  height: 100%;
}

.homeStyles_cardImage__2YveX {
  margin-top: 2rem;
  width: 50% !important;
  height: 70%!important;
  margin-left: auto;
  margin-right: auto;
  object-fit: cover;

}

.homeStyles_cardTitle__c_Ean {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  /* margin-left: auto;
  margin-right: auto; */
  

}

.homeStyles_cardContent__aW0yw {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: 100%;
  padding-bottom: 1rem !important;
  

}
.homeStyles_pageLogo__HrBs6{
  width:35%
}
.homeStyles_heroimagediv__3jYA8{
  padding-bottom: 3rem;
  padding-top:1rem;
}
.homeStyles_PageTitle__2E0xi{
  /* overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; */
  word-wrap: break-word;

  color:#000000DE;
  padding-top:2rem;
  margin: bottom 2rem;
  font-size: 2rem !important; 
}

body {
  background-color: #F3F3F3;

}
