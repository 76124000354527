.image {
  /* Image styles */
}

.title {
  /* Title styles */
}

.subtitle {
  /* Subtitle styles */
}

.spacing {
  /* Spacing styles */
}
.cardGridItem {
  display: flex;
}

.cardWrapper {
  width: 100%;
  height: 100%;
}

.cardImage {
  margin-top: 2rem;
  width: 50% !important;
  height: 70%!important;
  margin-left: auto;
  margin-right: auto;
  object-fit: cover;

}

.cardTitle {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  /* margin-left: auto;
  margin-right: auto; */
  

}

.cardContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: 100%;
  padding-bottom: 1rem !important;
  

}
.pageLogo{
  width:35%
}
.heroimagediv{
  padding-bottom: 3rem;
  padding-top:1rem;
}
.PageTitle{
  /* overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; */
  word-wrap: break-word;

  color:#000000DE;
  padding-top:2rem;
  margin: bottom 2rem;
  font-size: 2rem !important; 
}

body {
  background-color: #F3F3F3;

}